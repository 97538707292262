var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('v-card-title',[_vm._v(" Актеры "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Поиск","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headersActors,"items":_vm.ACTORS,"item-key":"name","search":_vm.search},scopedSlots:_vm._u([{key:"item.photo",fn:function(ref){
var item = ref.item;
return [_c('v-img',{staticClass:"mt-2 mb-2",attrs:{"src":'https://rocky-beyond-96534.herokuapp.com' + item.photo_thumbnail,"max-width":"100"}})]}},{key:"item.button",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"small":"","fab":"","text":"","color":"primary"},on:{"click":function($event){return _vm.editActor(item.site_id)}}},[_c('v-icon',[_vm._v("fa-pencil-alt")])],1)]}},{key:"item.audio",fn:function(ref){
var item = ref.item;
return [_c('row',{attrs:{"justify":"center","align":"center"}},[_c('audio',{attrs:{"controls":"","src":'https://rocky-beyond-96534.herokuapp.com' + item.voice_file}}),_c('p',[_vm._v(" "+_vm._s(item.file_name)+" ")])])]}},{key:"item.payment_info",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.price)+" "+_vm._s(item.paypal_email)+" "+_vm._s(item.extra_price)+" ")]}},{key:"item.contacts",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.email)+" "),_c('br'),_vm._v(" "+_vm._s(item.phone)+" "),_c('br'),_vm._v(" "+_vm._s(item.upwork_url)+" "),_c('br'),_vm._v(" "+_vm._s(item.fiverr_url)+" ")]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }