<template>
  <v-card class="pl-7 pr-7">
    <v-card-title> Фильтр </v-card-title>
    <v-divider inset></v-divider>
    <v-card-actions class="mt-4">
      <v-select label="Пол" outlined :items="gender" v-model="selectedGender"></v-select>
    </v-card-actions>
    <v-card-actions class="mt-n7">
      <v-select label="Язык" outlined :items="LANGUAGE" v-model="selectedLanguage" ></v-select>
    </v-card-actions>
    <v-card-actions class="mt-n7">
      <v-select label="Диалект" outlined :items="DIALECT" v-model="selectedDialect"></v-select>
    </v-card-actions>
    <v-card-actions class="mt-n7">
      <v-select label="Страна" outlined :items="COUNTRY" v-model="selectedCountry"></v-select>
    </v-card-actions>
    <v-card-actions class="mt-n7 pb-4">
      <v-btn color="primary" @click="filterActors">Применить</v-btn>
      <v-btn color="error" @click="drop">Сбросить</v-btn>
    </v-card-actions>

  </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  data() {
    return {
      selectedGender: '',
      selectedLanguage: '',
      selectedDialect: '',
      selectedCountry: '',
      gender: [
        {text: "Мужской", value: "M"},
        {text: "Женский", value: "F"}
      ],
    };
  },
  computed: {
    ...mapGetters(["LANGUAGE", "DIALECT", "COUNTRY"])
  },
  mounted() {
    this.$store.dispatch("GET_FILLERS")
  },
  methods: {
    ...mapActions(["FILTER","DROP"]),
    filterActors() {
      let propsFilter = {
        gender: this.selectedGender,
        language: this.selectedLanguage,
        dialect: this.selectedDialect,
        country: this.selectedCountry
      }
      this.FILTER(propsFilter)
    },
    drop() {
      this.selectedGender = "";
      this.selectedLanguage = "";
      this.selectedDialect = "";
      this.selectedCountry = "";
      this.DROP();
    }
  }
};
</script>
