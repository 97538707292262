<template>
  <v-container fluid>
    <v-row>
      <!-- Основной раздел(таблица) -->
      <v-col cols="12" lg="9" md="9" class="home__table">
        <!-- Отображение людей в таблице -->
        <UsersTable />
      </v-col>
      <!-- Фильтрация по полу, языку, диалекту, стране -->
      <v-col cols="12" lg="3" md="3" class="home__filter hidden-sm-and-down" >
        <v-btn width="100%" color="primary" @click="$router.push('/create')">Создать актера</v-btn>
        <ActorsFilter class="mt-4"/>
      </v-col>
      <v-btn fab fixed bottom right small
      color="primary"
      class="hidden-md-and-up"
      @click="$router.push('/create')"
      ><v-icon small>fa-plus</v-icon></v-btn>

      <v-dialog
      v-model="dialog"
      max-width="400"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn fab fixed bottom right small
          color="primary"
          class="hidden-md-and-up mb-12"
          v-bind="attrs"
          v-on="on"
      ><v-icon small>fa-filter</v-icon></v-btn>
      </template>
      <v-btn
            color="primary"
            fab
            @click="dialog = false"
            fixed
            top
            right
            small
          >
            X
          </v-btn>
      <ActorsFilter />

    </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
import UsersTable from "../components/UsersTable";
import ActorsFilter from "../components/ActorsFilter";
export default {
  name: "Home",
  components: {
    UsersTable,
    ActorsFilter
  },
  data() {
    return {
      dialog: false
    }
  }
};
</script>
