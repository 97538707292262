<template>
  <div>
    <v-card>
      <v-card-title>
        Актеры
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Поиск"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headersActors"
        :items="ACTORS"
        item-key="name"
        class="elevation-1"
        :search="search"
      >
        <template v-slot:item.photo="{ item }">
          <v-img
            class="mt-2 mb-2"
            :src="
              'https://rocky-beyond-96534.herokuapp.com' + item.photo_thumbnail
            "
            max-width="100"
          />
        </template>
        <template v-slot:item.button="{ item }">
          <v-btn small fab text color="primary" @click="editActor(item.site_id)"
            ><v-icon>fa-pencil-alt</v-icon></v-btn
          >
        </template>
        <template v-slot:item.audio="{ item }">
          <row justify="center" align="center">
            <audio
              controls
              :src="
                'https://rocky-beyond-96534.herokuapp.com' + item.voice_file
              "
            ></audio>

          <p> {{item.file_name}} </p>
          </row>
        </template>
        <template v-slot:item.payment_info="{ item }">
          {{ item.price }}
          {{ item.paypal_email }}
          {{ item.extra_price }}
        </template>
        <template v-slot:item.contacts="{ item }">
          {{ item.email }} <br />
          {{ item.phone }} <br />
          {{ item.upwork_url }} <br />
          {{ item.fiverr_url }}
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  created() {
    this.$store.dispatch("GET_PERSONS_LIST");
  },
  data() {
    return {
      search: "",
      headersActors: [
        {
          text: "Фото",
          align: "start",
          sortable: false,
          value: "photo",
          filterable: false,
        },
        { text: "ID", value: "site_id" },
        { text: "ФИО", value: "full_name" },
        { text: "Пол", value: "gender" },
        { text: "Язык", value: "language.language" },
        { text: "Диалект", value: "dialect.dialect" },
        { text: "Контакты", value: "contacts", sortable: false },
        { text: "Платежные данные", value: "payment_info", sortable: false },
        { text: "Аудио", value: "audio", sortable: false },
        { text: "", value: "button", sortable: false },
      ],
    };
  },
  computed: {
    ...mapGetters(["ACTORS"]),
  },
  methods: {
    editActor(id) {
      this.$router.push("/edit/" + id);
    },
  },
};
</script>

<style>
</style>
